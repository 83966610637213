export default function saveAPI(items){
	var formData = new FormData();
	formData.append('body', JSON.stringify(items));

	fetch("setEvent.php", {
		method:'POST',
		head:{
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		},
		body:formData,
	});
}