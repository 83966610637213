import React, { useState, useEffect } from 'react';
import './css/eventCalc.css';
import './css/variables.css';

export default function Valentine(props){
	const [valentime, setValentime] = useState();
	const [valData, setValData] = useState();
	const [isLoaded, setIsLoaded] = useState();
	const [error, setError] = useState();


	useEffect(() => {
		fetch('eventData.json')
		.then(res => res.json())
		.then(
			(result) => {
				setIsLoaded(true);
				setValData(result.Valentine);
			},
			(error) => {
				setIsLoaded(true);
				setError(error);
			}
		);
	
		if(valData){
			setInterval(() => {
				const start_string = valData[3]+"T"+valData[2]+":00";
				const start = new Date(start_string) / 60000;
				const duration = (valData[0]* 24 * 60) + (valData[1]* 60);
				const today = new Date().getTime() / 60000;
				const small_prodiction_time = 35.12;
				const small_graskar_minut = 1 / small_prodiction_time *  60 * 24 * valData[0];
		
				const remaining_time = start+duration-today;
				const small = remaining_time/duration * small_graskar_minut;
				setValentime(Math.ceil(small));
			}, 1000);
		}
	}, [valData]);
	if(valData && valentime > 0){
		if(valData[4]){
			return(
				<div id="tso_valentine_timer">
					<image className="flowerfarm"/>
					<div id="tso_valentine_flowerfarm">
						<div id="flower_frame">
							<div> Flower Farm: {valentime}</div>
						</div>
					</div>
					<image className="flowerfarm"/>
				</div>
			);
		}else{
			return <div></div>;
		}
	}else if(valData && valentime < 1){
		if(valData[4]){
			return(
				<div id="tso_valentine_timer">
					<image className="flowerfarm"/>
					<div id="tso_valentine_flowerfarm">
						<div id="flower_frame">
							<div> The Valentines Event Have Ended! </div>
						</div>
					</div>
					<image className="flowerfarm"/>
				</div>
			)
		}else{
			return <div></div>;
		}
	}else if(!valData){
		return(
			<div id="tso_valentine_timer">
			<image className="flowerfarm"/>
			<div id="tso_valentine_flowerfarm">
				<div id="flower_frame">
					<div> Loading... </div>
				</div>
			</div>
			<image className="flowerfarm"/>
		</div>
		)
	}
}

export function ValentineMini(props){
	const [valentime, setValentime] = useState();
	const [valData, setValData] = useState();
	const [isLoaded, setIsLoaded] = useState();
	const [error, setError] = useState();


	useEffect(() => {
		fetch('eventData.json')
		.then(res => res.json())
		.then(
			(result) => {
				setIsLoaded(true);
				setValData(result.Valentine);
			},
			(error) => {
				setIsLoaded(true);
				setError(error);
			}
		);
	
		if(valData){
			setInterval(() => {
				const start_string = valData[3]+"T"+valData[2]+":00";
				const start = new Date(start_string) / 60000;
				const duration = (valData[0]* 24 * 60) + (valData[1]* 60);
				const today = new Date().getTime() / 60000;
				const small_prodiction_time = 35.12;
				const small_graskar_minut = 1 / small_prodiction_time *  60 * 24 * valData[0];
		
				const remaining_time = start+duration-today;
				const small = remaining_time/duration * small_graskar_minut;
				setValentime(Math.ceil(small));
			}, 1000);	
		}
	}, [valData]);
	if(valData && valentime > 0){
		if(valData[4]){
			return(
				<div id="tso_valentine_mini">
					Flower Farm: {valentime}
				</div>
			);
		}else{
			return <div></div>;
		}
	}else if(valData && valentime < 1){
		if(valData[4]){
			return(
				<div id="tso_valentine_mini">
					The Valentines Event Have Ended!
				</div>
			)
		}else{
			return <div></div>;
		}
	}else if(!valData){
		return(
			<div id="tso_valentine_mini">
				Loading...
			</div>
		)
	}
}