import './App.css';
import MainMenu from './menus/MainMenu';

function App() {
  return (<>
      <MainMenu />
    </>
  );
}

export default App;
