export default function Home(){
    return(
        <>
            <h1>Tre Broderfolk [TSO]</h1>
            <p>
                Kære alle medlemmer af Tre Broderfolk, Velkommen til tso.fjordhund.dk.
            </p>
            <p>
                Denne side er beregnet til at vi, i guilden, kan dele TSO relaterede resourcer med hinanden. <br/>
                Hvis i har materiale i mener er relevante at have på siden, kontakt da Micwei. <br/>
                Det er bedst hvis i på forhånd har gemt filerne som enten .pdf, .txt eller .html.
            </p>
            
            <p>
                Med de venligste hilsener og et ønske om rigtigt god spillelyst!
                - Jonas A. Offersen
            </p>
        </>
    )
}