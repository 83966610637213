import React, { useState, useEffect } from 'react';
import './css/eventCalc.css';
import './css/variables.css';

export default function Halloween(props){
	const [smalltime, setSmalltime] = useState();
	const [commontime, setCommontime] = useState();
	const [nobletime, setNobletime] = useState();
	const [halData, setHalData] = useState();
	const [isLoaded, setIsLoaded] = useState();
	const [error, setError] = useState();


	useEffect(() => {
		fetch('eventData.json')
		.then(res => res.json())
		.then(
			(result) => {
				setIsLoaded(true);
				setHalData(result.Halloween);
			},
			(error) => {
				setIsLoaded(true);
				setError(error);
			}
		);
	
		if(halData){
			setInterval(() => {
				let start_string = halData[3]+"T"+halData[2]+":00";
				let start = new Date(start_string) / 60000;
				let duration = (halData[0]* 24 * 60) + (halData[1]* 60);
				let today = new Date().getTime() / 60000;
				let small_graskar_minut = 1 / halData[4] *  60 * 24 * halData[0];
				let common_graskar_minut = 1 / halData[5] *  60 * 24 * halData[0];
				let noble_graskar_minut = 1 / halData[6] *  60 * 24 * halData[0];
		
				let remaining_time = start+duration-today;
				let small = remaining_time/duration * small_graskar_minut;
				let common = remaining_time/duration * common_graskar_minut;
				let noble = remaining_time/duration * noble_graskar_minut;
				setSmalltime(Math.ceil(small));
				setCommontime(Math.ceil(common));
				setNobletime(Math.ceil(noble));
			}, 1000);
		}
	}, [halData]);
	if(halData && smalltime  > 0 && commontime  > 0 && nobletime > 0){
		if(halData[7]){
			return(
				<div id="tso_halloween_timer">
					<image className="pumpkin_farm"/>
					<div id="tso_halloween_cemetaries">
						<div id="flower_frame">
							<div>Small: {smalltime}</div>
							<div>Common: {commontime}</div>
							<div>Noble: {nobletime}</div>
						</div>
					</div>
					<image className="pumpkin_farm"/>
				</div>
			);
		}else{
			return <div></div>;
		}
	}else if(halData && smalltime  < 1 && commontime  < 1 && nobletime < 1){
		if(halData[7]){
			return(
				<div id="tso_halloween_timer">
					<image className="pumpkin_farm"/>
					<div id="tso_halloween_cemetaries">
						<div id="flower_frame">
							<div> The Halloween Event Have Ended! </div>
						</div>
					</div>
					<image className="pumpkin_farm"/>
				</div>
			)
		}else{
			return <div></div>;
		}
	}else if(!halData){
		return(
			<div id="tso_halloween_timer">
			<image className="pumpkin_farm"/>
			<div id="tso_halloween_cemetaries">
				<div id="flower_frame">
					<div> Loading... </div>
				</div>
			</div>
			<image className="pumpkin_farm"/>
		</div>
		)
	}
}

export function HalloweenMini(props){
	const [smalltime, setSmalltime] = useState();
	const [commontime, setCommontime] = useState();
	const [nobletime, setNobletime] = useState();
	const [halData, setHalData] = useState();
	const [isLoaded, setIsLoaded] = useState();
	const [error, setError] = useState();


	useEffect(() => {
		fetch('eventData.json')
		.then(res => res.json())
		.then(
			(result) => {
				setIsLoaded(true);
				setHalData(result.Halloween);
			},
			(error) => {
				setIsLoaded(true);
				setError(error);
			}
		);
	
		if(halData){
			setInterval(() => {
				let start_string = halData[3]+"T"+halData[2]+":00";
				let start = new Date(start_string) / 60000;
				let duration = (halData[0]* 24 * 60) + (halData[1]* 60);
				let today = new Date().getTime() / 60000;
				let small_graskar_minut = 1 / halData[4] *  60 * 24 * halData[0];
				let common_graskar_minut = 1 / halData[5] *  60 * 24 * halData[0];
				let noble_graskar_minut = 1 / halData[6] *  60 * 24 * halData[0];
		
				let remaining_time = start+duration-today;
				let small = remaining_time/duration * small_graskar_minut;
				let common = remaining_time/duration * common_graskar_minut;
				let noble = remaining_time/duration * noble_graskar_minut;
				setSmalltime(Math.ceil(small));
				setCommontime(Math.ceil(common));
				setNobletime(Math.ceil(noble));
			}, 1000);
		}
	}, [halData]);
	if(halData && smalltime  > 0 && commontime  > 0 && nobletime > 0){
		if(halData[7]){
			return(
				<div id="tso_halloween_mini">
					<div>Small: {smalltime}</div>
					<div>Common: {commontime}</div>
					<div>Noble: {nobletime}</div>
				</div>
			);
		}else{
			return <div></div>;
		}
	}else if(halData && smalltime  < 1 && commontime  < 1 && nobletime < 1){
		if(halData[7]){
			return(
				<div id="tso_halloween_mini">
					<div> The Halloween Event Have Ended! </div>
				</div>
			)
		}else{
			return <div></div>;
		}
	}else if(!halData){
		return(
			<div id="tso_halloween_mini">
				<div> Loading... </div>
			</div>
		)
	}
}
