import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Valentine from '../valentine/Valentine';
import Halloween from '../halloween/Halloween';
import Home from '../Home';
import EventSettings from '../components/EventSettings';

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

export default function MainTabs(props) {
	switch (props.page) {
		case 'home':
			return (
				<Box sx={{ 
					width: '100%',
					height: '70vh',
					background: "var(--accentColor)", 
					display:'block',
					position: 'relative',
					top: '192px',
					justifyContent: 'center',
					textAlign: 'center',
					paddingTop: '15px'

				}}>
					<Home />
				</Box>

			);
		case 'halloween':
			return (
				<Box sx={{ width: '100%' }}>
					<Halloween />
				</Box>
			);
		case 'valentine':
			return (
				<Box sx={{ width: '100%' }}>
					<Valentine />
				</Box>
			);
		case 'eventSettings':
			return (
				<Box sx={{ width: '100%' }}>
					<EventSettings />
				</Box>
			)
			default:
			return (
				<Box sx={{ 
					width: '100%',
					height: '70vh',
					background: "var(--accentColor)", 
					display:'flex', 
					position: 'relative',
					top: '192px',
					justifyContent: 'center'
				}}>
					<object data={props.page} width="100%" height="100%"></object> 
				</Box>
			)
			break;
	}
}
