import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import SendIcon from '@mui/icons-material/Send';
import saveAPI from '../App/saveAPI';

export default function EventSettings(props) {
    const [eventData, setEventData] = useState();
    const [loadingEventData, setLoadingEventData] = useState(false);
    const [eventError, setEventError] = useState();
    const [halloweenButton, setHalloweenButton] = useState();
    const [valentineButton, setValentineButton] = useState();

    function handleStartEvent(tempData, hall, val){
        tempData.Halloween[7] = (hall === true ? true : false);
        tempData.Valentine[4] = (val === true ? true : false);
        saveAPI(tempData);
    }
    
	useEffect(() => {
		fetch('./eventData.json')
		.then(res => res.json())
		.then(
			(result) => {
				setLoadingEventData(true);
                setEventData(result);
			},
			(error) => {
				setLoadingEventData(true);
				setEventError(error);
			}
		)
	}, []);
    
	if (eventError) {
		return <div>Error: {eventError.message}</div>;
	} else if (!loadingEventData) {
		return <div>Loading...</div>;
	} else {
        return(
            <Box sx={{ 
                width: '100%',
                height: '70vh',
                background: "var(--accentColor)", 
                display:'flex',
                position: 'relative',
                top: '192px',
                justifyContent: 'center'
            }}>
                <Box sx={{ 
                    display:'block',
                    margin:'25px',
                    textAlign:'center',
                    width:'350px'
                }}>
                    <h1>Halloween Settings</h1>
                    <Divider sx={{marginTop:'25px'}}> Varighed i dage </Divider>
                    <TextField 
                        fullWidth
                        sx={{backgroundColor:'white'}} 
                        id="halloween_varighed" 
                        label="Varighed (i dage)" 
                        defaultValue={ eventData.Halloween[0] < 1 ? 1 : eventData.Halloween[0] }
                        onKeyDown={e => {
                            e.key === 'ArrowUp' ? e.target.value++ : e.target.value = e.target.value;
                            e.key === 'ArrowDown' ? (e.target.value > 1 ? e.target.value-- : e.target.value = e.target.value) : e.target.value = e.target.value;
                        }}
                        onInput={e => {
                            let temp = Number(e.target.value.replace(/\D/g,''));
                            if(typeof temp !== "number" || temp < 1){ e.target.value = 1; }else{ e.target.value = temp; }
                        }}
                        onChange={e => {
                            let tempData = eventData;
                            tempData.Halloween[0] = e.target.value;
                            setEventData(tempData);
                        }}
                        variant="standard"
                        required />
                    <Divider sx={{marginTop:'25px'}}> Extra Timer</Divider>
                    <TextField 
                        fullWidth
                        sx={{backgroundColor:'white'}} 
                        id="halloween_extra" 
                        label="Extra timer" 
                        defaultValue={eventData.Halloween[1]}
                        onKeyDown={e => {
                            e.key === 'ArrowUp' ? e.target.value++ : e.target.value = e.target.value;
                            e.key === 'ArrowDown' ? (e.target.value > 0 ? e.target.value-- : e.target.value = e.target.value) : e.target.value = e.target.value;
                        }}
                        onInput={e => {
                            let temp = Number(e.target.value.replace(/\D/g,''));
                            if(typeof temp !== "number" || temp < 0){ e.target.value = 0; }else{ e.target.value = temp; }
                        }}
                        onChange={e => {
                            let tempData = eventData;
                            tempData.Halloween[1] = e.target.value; 
                            setEventData(tempData);
                        }}
                        variant="standard"
                        required />
                    <Divider sx={{marginTop:'25px'}}> Start Tidspunkt </Divider>
                    <TextField 
                        fullWidth
                        sx={{backgroundColor:'white'}} 
                        id="halloween_start_tid" 
                        label="Start tid" 
                        defaultValue={eventData.Halloween[2]}
/*                        onKeyDown={e => {
                            e.key === 'ArrowUp' ? e.target.value++ : e.target.value = e.target.value;
                            e.key === 'ArrowDown' ? (e.target.value > 1 ? e.target.value-- : e.target.value = e.target.value) : e.target.value = e.target.value;
                        }}*/
                        onInput={e => {
                            let time = e.target.value.split("");
                            if(time.length !== 5){
                                for( let i = time.length; i < 6; i++){
                                    if(time.length !== 2){
                                        time.push("0");
                                    }else{
                                        time.psuh(":")
                                    }
                                }
                            }
                            for( let i = 0; i < time.length; i++){
                                i !== 2 ? time[i].replace(/\D/g,'0') : time[i] = ":";
                            }
                            e.target.value = time.join("");
                        }}
                        onChange={e => {
                            let tempData = eventData;
                            tempData.Halloween[2] = e.target.value; 
                            setEventData(tempData);
                        }}
                        variant="standard"
                        required />
                    <Divider sx={{marginTop:'25px'}}> Start Dato</Divider>
                    <TextField 
                        fullWidth
                        sx={{backgroundColor:'white'}} 
                        id="halloween_start_dato" 
                        label="Start Dato" 
                        defaultValue={eventData.Halloween[3]} 
                        onChange={e => {
                            let tempData = eventData;
                            tempData.Halloween[3] = e.target.value; 
                            setEventData(tempData);
                        }}
                        variant="standard"
                        required />
                    <Divider />
                    <IconButton 
                        aria-label="Start Event" 
                        size="large"
                        onClick={() => {
                            handleStartEvent(eventData, !(eventData.Halloween[7]), false);
                            setHalloweenButton(eventData.Halloween[7] ? 'End Event' : 'Start Event');
                        }}
                        ><Box sx={{fontSize:"medium", marginRight:"5px"}}>{typeof halloweenButton === 'string' ? halloweenButton : eventData.Halloween[7] ? 'End Event' : 'Start Event'}</Box><SendIcon/></IconButton>
                </Box>
                <Box sx={{ 
                    display:'block',
                    margin:'25px',
                    textAlign:'center',
                    width:'350px'
                }}>
                    <h1>Valentines Settings</h1>
                    <Divider sx={{marginTop:'25px'}}> Varighed i dage </Divider>
                    <TextField 
                        fullWidth
                        sx={{backgroundColor:'white'}} 
                        id="valentine_varighed" 
                        label="Varighed (i dage)" 
                        defaultValue={eventData.Valentine[0]}
                        onKeyDown={e => {
                            e.key === 'ArrowUp' ? e.target.value++ : e.target.value = e.target.value;
                            e.key === 'ArrowDown' ? (e.target.value > 1 ? e.target.value-- : e.target.value = e.target.value) : e.target.value = e.target.value;
                        }}
                        onInput={e => {
                            let temp = Number(e.target.value.replace(/\D/g,''));
                            if(typeof temp !== "number" || temp < 1){ e.target.value = 1; }else{ e.target.value = temp; }
                        }}
                        onChange={e => {
                            let tempData = eventData;
                            tempData.Valentine[0] = e.target.value; 
                            setEventData(tempData);
                        }}

                        variant="standard"
                        required />
                    <Divider sx={{marginTop:'25px'}}> Extra Timer</Divider>
                    <TextField 
                        fullWidth
                        sx={{backgroundColor:'white'}} 
                        id="valentine_extra" 
                        label="Extra timer" 
                        defaultValue={eventData.Valentine[1]}
                        onKeyDown={e => {
                            e.key === 'ArrowUp' ? e.target.value++ : e.target.value = e.target.value;
                            e.key === 'ArrowDown' ? (e.target.value > 0 ? e.target.value-- : e.target.value = e.target.value) : e.target.value = e.target.value;
                        }}
                        onInput={e => {
                            let temp = Number(e.target.value.replace(/\D/g,''));
                            if(typeof temp !== "number" || temp < 0){ e.target.value = 0; }else{ e.target.value = temp; }
                        }}
                        onChange={e => {
                            let tempData = eventData;
                            tempData.Valentine[1] = e.target.value; 
                            setEventData(tempData);
                        }}

                        variant="standard"
                        required />
                    <Divider sx={{marginTop:'25px'}}> Start Tidspunkt </Divider>
                    <TextField 
                        fullWidth
                        sx={{backgroundColor:'white'}} 
                        id="valentine_start_tid" 
                        label="Start tid" 
                        defaultValue={eventData.Valentine[2]}
                        onChange={e => {
                            let tempData = eventData;
                            tempData.Valentine[2] = e.target.value; 
                            setEventData(tempData);
                        }}

                        variant="standard"
                        required />
                    <Divider sx={{marginTop:'25px'}}> Start Dato</Divider>
                    <TextField 
                        fullWidth
                        sx={{backgroundColor:'white'}} 
                        id="valentine_start_dato" 
                        label="Start Dato" 
                        defaultValue={eventData.Valentine[3]}
                        onChange={e => {
                            let tempData = eventData;
                            tempData.Valentine[3] = e.target.value; 
                            setEventData(tempData);
                        }}

                        variant="standard"
                        required />
                    <Divider />
                    <IconButton 
                        aria-label="Start Event" 
                        size="large"
                        onClick={() => {
                            handleStartEvent(eventData, false, !(eventData.Valentine[4]));
                            setValentineButton(eventData.Valentine[4] ? 'End Event' : 'Start Event');
                        }}
                        ><Box sx={{fontSize:"medium", marginRight:"5px"}}>{typeof valentineButton === 'string' ? valentineButton : eventData.Valentine[4] ? 'End Event' : 'Start Event'}</Box><SendIcon/></IconButton>
                </Box>
            </Box>
        )
    }
}