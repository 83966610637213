import React, { useState } from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import json from './users.json';


export default function Login(props) {
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  
	const handleSubmit = async e => {
		e.preventDefault();
		for(let i = 0; i < json.users.length; i++){
			if(username === json.users[i].username && password === json.users[i].password){
				props.setToken(json.users[i].token);
			}
		}
	}

	return(
		<Box 
			sx={{ 
				width: '100%',
				height: '70vh',
				background: "var(--accentColor)", 
				display:'flex', 
				position: 'relative',
				top: '192px',
				justifyContent: 'center',
				flexDirection: 'column',
				textAlign: 'center'
			}}
		>
			<h2>Log ind for at få adgang til administrator indhold</h2>
			<form onSubmit={handleSubmit} style={{display:"flex", flexDirection:"row", justifyContent: 'center'}}>
				<input type="text" placeholder="Username" onChange={e => setUserName(e.target.value)} style={{
					padding:'10px',
					borderRadius:'5px',
					fontSize:'larger'
				}}/>
				<input type="password" placeholder="Password" onChange={e => setPassword(e.target.value)} style={{
					padding:'10px',
					borderRadius:'5px',
					fontSize:'larger',
					marginLeft:'5px', 
					marginRight:'5px'
				}}/>
				<button type="submit" style={{
					padding: '10px',
					borderRadius: '5px',
					fontSize: 'larger',
					backgroundColor: 'var(--backgroundColor)',
					color: 'var(--invertedTextColor)',
					cursor: 'pointer',
					width: '150px'
				}}>Log ind</button>
			</form>
		</Box>
	)
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired
};