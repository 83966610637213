import React,{ useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import HomeIcon from '@mui/icons-material/Home';
import LinkIcon from '@mui/icons-material/Link';
import { Link } from 'react-router-dom';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FavoriteIcon from '@mui/icons-material/Favorite';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';

import '../halloween/css/variables.css';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import AppBarImage from '../header.jpg';
import MainTabs from './MainTabs';
//import hEvent from '../halloween/Halloween.json';
//import vEvent from '../valentine/Valentine.json';
import Tsodivider from './Tsodivider';
import { HalloweenMini } from '../halloween/Halloween';
import { ValentineMini } from '../valentine/Valentine';

import Login from '../Login/Login';
import useToken from '../App/useToken';
import { OpenInFullRounded } from '@mui/icons-material';

const drawerWidth = 240;
const theme = createTheme({
	components: {
		MuiDrawer: {
			styleOverrides: {
				paper: {
					backgroundColor: "var(--accentColor)",
					color: "var(--textColor)",
				}
			}
		},
		MuiDivider: {
			styleOverrides:{
				root: {
					borderColor: "var(--borderColor)"
				}
			}
		}
	}
});

var hEvent = false;
var vEvent = false;

export default function PermanentDrawerLeft() {
	const { token, setToken } = useToken();

	const [login, setLogin] = useState(false);
	const [halloween, setHalloween] = useState(false);
	const [valentine, setValentine] = useState(false);
	const [home, setHome] = useState(true);
	const [guides, setGuides] = useState(false);
	const [links, setLinks] = useState(false);
	const [eventSettings, setEventSettings] = useState(false);

	const icons = [
		<HomeIcon/>,
		<HelpCenterIcon/>,
		<FileDownloadIcon/>,
		<LinkIcon/>
	];
	const [currentPage, setCurrentPage] = useState();
	let menuItems = ['Hjem', 'Guides', 'Filer', 'Links'];
	const [error, setError] = useState(null);
	const [isLoaded, setIsLoaded] = useState(false);
	const [isLoadedEvent, setIsLoadedEvent] = useState(false);
	const [MenuData, setMenuData] = useState([]);
	const [eventData, setEventData] = useState();

	useEffect(() => {
		fetch('menu.php')
		.then(res => res.json())
		.then(
			(result) => {
				setIsLoaded(true);
				setMenuData(result);
			},
			(error) => {
				setIsLoaded(true);
				setError(error);
			}
		);
		fetch('eventData.json')
		.then(res => res.json())
		.then(
			(result) => {
				setIsLoadedEvent(true);
				hEvent = result.Halloween[7];
				vEvent = result.Valentine[4];
				setEventData(result);
			},
			(error) => {
				setIsLoadedEvent(true);
				setError(error);
			}
		);
	}, []);

	function LoginButton(){
		return(
			<ListItem button 
				key={'Login'} 
				onClick={() => {
					setHome( show => false );
					setGuides( show => false );
					setLinks( show => false);
					setEventSettings( show => false );
					setHalloween( show => false );
					setValentine( show => false );
					setLogin( show => true );
					setCurrentPage("");
				}}
			>
				<ListItemIcon>
					<LoginIcon/>
				</ListItemIcon>
				<ListItemText primary={'Log ind'} />
			</ListItem>
		)
	}
	function LogoutButton(){
		return(
			<ListItem button 
				key={'Logout'} 
				onClick={() => {
					setHome( show => true );
					setGuides( show => false );
					setLinks( show => false);
					setEventSettings( show => false );
					setHalloween( show => false );
					setValentine( show => false );
					setLogin( show => false );
					setCurrentPage("");
					sessionStorage.clear();
					setToken(false);
				}}
			>
				<ListItemIcon>
					<LogoutIcon/>
				</ListItemIcon>
				<ListItemText primary={'Log ud'} />
			</ListItem>
		)
	}
	function EventSettingsButton(){
		return(
			<ListItem button 
				key={'Logout'} 
				onClick={() => {
					setHome( show => false );
					setGuides( show => false );
					setLinks( show => false);
					setEventSettings( show => true );
					setHalloween( show => false );
					setValentine( show => false );
					setLogin( show => false );
					setCurrentPage("");
				}}
			>
				<ListItemIcon>
					<SettingsIcon />
				</ListItemIcon>
				<ListItemText primary={'Event Settings'} />
			</ListItem>
		)
	}
	function openUrl(url){
		window.open(url, '_blank').focus();
		document.location.reload();
	}
	function SwitchLoginButton(){
		if(typeof token === "string"){
			return (<LogoutButton />);
		}else{
			return (<LoginButton />);
		}
	}

	if(hEvent){
		menuItems.push('Halloween');
		icons.push(<DarkModeIcon/>);
	}
	if(vEvent){
		menuItems.push('Valentine');
		icons.push(<FavoriteIcon/>);
	}
	if (error) {
		return <div>Error: {error.message}</div>;
	} else if (!isLoaded && !isLoadedEvent) {
		return <div>Loading...</div>;
	} else {
		return (
			<ThemeProvider theme={theme}>
				<Box sx={{ display: 'flex' }}>
					<CssBaseline />
					<AppBar
						position="fixed"
						sx={{ 
							width: `calc(100% - ${drawerWidth}px)`, 
							ml: `${drawerWidth}px`,
							backgroundImage: `url(${AppBarImage})`,
							height:'195px',
							backgroundRepeat:'no-repeat',
							backgroundSize:'cover'
						}}
					>
						<Toolbar>
							<HalloweenMini />
							<ValentineMini />
						</Toolbar>
					</AppBar>
					<Drawer
						sx={{
							width: drawerWidth,
							flexShrink: 0,
							'& .MuiDrawer-paper': {
								width: drawerWidth,
								boxSizing: 'border-box',
							},
						}}
						variant="permanent"
						anchor="left"
					>
						<Toolbar>
							<Typography variant="h6" component="div" noWrap >
								tso.fjordhund.dk
							</Typography>
						</Toolbar>
						<Tsodivider />
						<List>
							{menuItems.map((text, index) => (
								<ListItem button key={text}
								onClick={() =>{
									switch (index) {
										case 0:
											setHome( show => true );
											setGuides( show => false );
											setLinks( show => false);
											setEventSettings( show => false );
											setHalloween( show => false );
											setValentine( show => false );
											setLogin( show => false );
											setCurrentPage("");
											break;
										case 1:
											setHome( show => false );
											setGuides( show => !guides );
											setLinks( show => false );
											setEventSettings( show => false );
											setHalloween( show => false );
											setValentine( show => false );
											setLogin( show => false );
											setCurrentPage("");
											break;
										case 2:
											setHome( show => false );
											setGuides( show => false );
											setLinks( show => false );
											setEventSettings( show => false );
											setHalloween( show => false );
											setValentine( show => false );
											setLogin( show => false );
											setCurrentPage("");
											window.open('/ftp', '_self');
											break;
										case 3:
											setHome( show => false );
											setGuides( show => false );
											setLinks( show => !links );
											setEventSettings( show => false );
											setHalloween( show => false );
											setValentine( show => false );
											setLogin( show => false );
											setCurrentPage("");
											break;
										case 4:
											setHome( show => false );
											setGuides( show => false );
											setLinks( show => false );
											setEventSettings( show => false );
											setHalloween( show => hEvent );
											setValentine( show => hEvent ? false : true );
											setLogin( show => false );
											setCurrentPage("");
											break;
										case 5:
											setHome( show => false );
											setGuides( show => false );
											setLinks( show => false );
											setEventSettings( show => false );
											setHalloween( show => false );
											setValentine( show => true );
											setLogin( show => false );
											setCurrentPage("");
											break;
										default:
											setHome( show => true );
											setLogin( show => false );
											break;
									}
								}}
								>
									<ListItemIcon>
										{icons[index]}
									</ListItemIcon>
									<ListItemText primary={text} />
								</ListItem>
							))}
						</List>
						<Tsodivider />
						{guides && <List>{MenuData.Guides.map((text,index) =>(
							<ListItem button key={text.name} onClick={() => {setCurrentPage(text.url)}}>
								<ListItemIcon>
								{icons[1]}
								</ListItemIcon>
								<ListItemText primary={text.name} />
							</ListItem>
						))}</List>}
						{guides && <Tsodivider />}
						{links && <List>{MenuData.Links.map((text,index) => (
							<ListItem button key={text.name} onClick={() => {openUrl(text.url)}}>
								<ListItemIcon>
									{icons[3]}
								</ListItemIcon>
								<ListItemText primary={text.name} />
							</ListItem>
						))}</List>}
						{links && <Tsodivider />}
						<List>
							{['Kontakt'].map((text, index) => (
								<ListItem button key={text}>
									<ListItemIcon>
										<ContactPageIcon/>
									</ListItemIcon>
									<ListItemText primary={text} />
								</ListItem>
							))}
							{ token === "admin" ? <EventSettingsButton /> : "" }
							<SwitchLoginButton />
							
						</List>
					</Drawer>
					<Box
						component="main"
						sx={{ flexGrow: 1, bgcolor: 'var(--backgroundColor)', p: 3, height:'100vh' }}
					>
						{ home && <Box><MainTabs page={'home'} /></Box> }
						{ halloween && <Box><MainTabs page={'halloween'} /></Box> }
						{ valentine && <Box><MainTabs page={'valentine'} /></Box> }
						{ currentPage && <Box><MainTabs page={currentPage} /></Box> }
						{ token === "admin" ? login && <Box><MainTabs page={'home'} /></Box> : token === "user" ? login && <Box><MainTabs page={'home'} /></Box> : login && <Box><Login setToken={ setToken }/></Box> }
						{ eventSettings && <Box><MainTabs page={'eventSettings'} /></Box> }
					</Box>
				</Box>
			</ThemeProvider>
		);
	}
}
